<template>
  <div v-if="dialogDetailReport && passListDetailReport">
    <v-dialog :value="dialogDetailReport" max-width="1600" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="_closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="2" class="text-left my-4">
              <v-toolbar-title>Detail Player Statement</v-toolbar-title>
            </v-col>
            <v-col cols="6" sm="6" md="3" class="text-left mt-4">
                <v-select
                  v-model="defaultSelected"
                  :items="selectChannel"
                  item-text="name"
                  item-value="id"
                  style="margin-top:-10px"
                  solo
                ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="4" sm="4" md="3" class="text-right">
              <v-text-field v-model="v_search" append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable class="mr-5" />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" sm="2" md="2" class="text-right">
              <v-btn class="white--text btn-custom mr-4 mt-3" color="green darken-1" depressed @click="search">
                <!-- @click="fetchListStatement(params)" -->
                SEARCH
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table style="font-family: 'khmer mef1'" class="elevation-1" item-key="_id._id" :headers="headers"
            :footer-props="footerProps" :items="passListDetailReport ? passListDetailReport.object : []"
            :server-items-length="passListDetailReport ? parseInt(passListDetailReport.objectCount.count) : null"
            :sort-desc.sync="dataRequest.descendingFormat" :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page" :items-per-page.sync="dataRequest.rowsPerPage" @update:page="updatePage"
            @update:items-per-page="updatePage" @update:sort-desc="updatePage" :loading="getLoading"
            mobile-breakpoint="0">
            <template v-slot:[`item`]="{ item }">
              <tr>
                
                <td>{{ passListDetailReport.object.indexOf(item) + 1 }}</td>
                <!-- <td>{{ item._id.app_id }}</td> -->
   <td class="text-xs-center pl-4 total-style font-weight-bold  py-2"
                style="
                  cursor:pointer
                ">
                    {{item.public_ip}}
                </td>
                <td>{{ item.date }}</td>
                <td>{{ item.fight_no }}</td>
                <td :class="item.co_type_of_betting.color">
                  {{ item.co_type_of_betting.name }}
                </td>
                <!-- <td>{{ item.amount ? currencyFormat(item.amount) : 0 }}</td> -->
                <td :class="item.co_result.color">{{ item.co_result.name }}</td>
                <td class="amount-bet-color ">
                  {{ currencyFormat(item.amount) }}
                </td>
                 <td >
                  {{ item.payout }}
                </td>
                <td class="font-weight-bold" :style="
                  item.amount_win <
                    0
                    ? 'color:#BA2D4A; '
                    : 'color:#39afd6'
                ">{{ item.amount_win ? currencyFormat(item.amount_win) : 0 }}</td>
                <td class="font-weight-bold">{{ item.cast ? currencyFormat(item.cast) : 0 }}</td>
             
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="5" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <!-- <td class="text-xs-center total-style pl-4  py-2">
                {{
                    passListDetailReport.objectCount.amount
                      ? currencyFormat(passListDetailReport.objectCount.amount)
                      : 0
                }}
              </td> -->
              <td></td>

              <td class="text-xs-center total-style pl-4  py-2">
                {{
                    passListDetailReport.objectCount.amount
                      ? currencyFormat(passListDetailReport.objectCount.amount)
                      : 0
                }}
              </td>
              <td></td>
              <td class="text-xs-center pl-4 total-style font-weight-bold  py-2" :style="
                passListDetailReport.objectCount.amount_win <
                  0
                  ? 'color:#BA2D4A'
                  : 'color:#39afd6'
              ">
                {{
                    passListDetailReport.objectCount.amount_win 
                      ? currencyFormat(
                        passListDetailReport.objectCount.amount_win 
                      )
                      : 0
                }}
              </td>

            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
     <v-dialog v-model="confirmDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 py-5">
          តើអ្នកនឹងលុប របាយការណ៏នេះមែនទេ ?
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="_closeDialog"
            >ថយ</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2 white--text"
            color="error"
            @click="_remove"
            >លុប</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["darkmode", "dialogDetailReport", "passListDetailReport", "dataRequest", "user", "passSelectedUser"],
  data: () => {
    return {
      headers: [
        {
          text: "ល.រ",
          align: "start",
          value: "no",
        },
        // { text: "កម្មវិធី", value: "app_id" },
          { text: "ip", value: "public_ip" },
        { text: "កាលបរិច្ឆេទ", value: "date" },
        { text: "ប្រកួតលេខ", value: "fight_no" },
        { text: "ប្រភេទចាក់", value: "co_type_of_betting.name" },
        // { text: "ប្រាក់រង្វិលជុំ", value: "trun_over" },
        { text: "លទ្ធិផល", value: "co_result.name" },
        { text: "សរុបប្រាក់ភ្នាល់", value: "amount" },
        { text: "Payout", value: "payout" },
        { text: "សរុបឈ្នះ/ចាញ់", value: "amount_win" },
        { text: "ប្រាក់សងត្រឡប់", value: "cast" },
      
      ],
      userChild: [],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      getLoading: false,
      confirmDialog: false,
      selectedData:[],
       defaultSelected: {
      id : 0,
      name : "បុស្តិ៍ទាំងអស់",
    },
    selectChannel:[
      {
      id : 0,
      name : "បុស្តិ៍ទាំងអស់",
      },
      {
      id : 1,
      name : "បុស្តិ៍ទី ១",
      },
      {
      id : 2,
      name : "បុស្តិ៍ទី ២",
      },
      {
      id : 3,
      name : "បុស្តិ៍ទី ៣",
      },
      {
      id : 4,
       name : "បុស្តិ៍ទី 4",
      },
    ],
    };
  },
  computed: {
    reportUrl: function () {
      if (this.user) {
        if (this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER") return "getViewPlayerDetailUsd";
        else return null;
      } else return null;
    },
  },
  methods: {
    search() {
      if (this.user.role_name == "AGENT-D" && this.user.role_name == "SUB-AGENT-D") this.$emit("getData5", this.v_search);
      else this.$emit("onSearch", this.v_search, this.defaultSelected);
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },
    updatePage() {
      this.getLoading = true;
      this.$emit("updatePage");
      this.getLoading = false;
    },
    _openConfirmDialog(item){
      this.selectedData = item;
      this.confirmDialog = true;
    },
    _closeDialog(){
      this.selectedData = []
      this.defaultSelected = {
      id : 0,
      name : "បុស្តិ៍ទាំងអស់",
    },
      this.$emit('onCloseDialog')
    },
    _remove(){
      let param = {
        channel_type : this.selectedData.channel_type,
        bet_id : this.selectedData._id,
        user_id : this.selectedData.user_id
      }
       this.$request
        .post({
          url: "/coChannel/cancelBetting",
          data: param,
        })
        .then((res) => {
          if (res.data.code) {
            this.updatePage();
            this._closeDialog();
              
          }
        }
         
        );
    }
  },
};
</script>

<style scoped>
.amount-bet-color {
  color: goldenrod !important;
}

.win-color {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.lose-color {
  color: #ba2d4a;
  font-weight: bold;
}

.cir-red {
  color: #ba2d4a;
  font-weight: bold;
}

.cir-blue {
  color: rgb(57, 175, 214);
  font-weight: bold;
}

.cir-tie {
  color: grey;
}

.cir-cancel {
  color: grey;
}

</style>
